const width = {
  default: 0,
}

const radius = {
  default: `3px`,
}

export default {
  radius: radius,
  width: width,
}